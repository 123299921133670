@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}
body {
	font-family: 'Raleway', sans-serif;
	background-color: #0e1620;
	box-sizing: border-box;
}
.drop-blue {
	filter: drop-shadow(3px 50px 50px #7878e6);
}
.drop-purple {
	filter: drop-shadow(3px 50px 50px #c95eff);
}
.drop-yellow {
	filter: drop-shadow(3px 50px 50px #feb914);
}

.anim {
	animation: 3s ease-in-out 0.66s infinite normal none running dwSZFG;
	animation-duration: 3s;
	animation-timing-function: ease-in-out;
	animation-delay: 0.66s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
	animation-name: dwSZFG;
}

@keyframes dwSZFG {
	0% {
		transform: translate(0px, 0px);
	}
	50% {
		transform: translate(0px, 10px);
	}
	100% {
		transform: translate(0px, 0px);
	}
}
